@font-face {
  font-family: 'PP Pangram Sans Rounded';
  src: url('/assets/fonts/PPPangramSansRounded-Bold.woff2') format('woff2'),
  url('/assets/fonts/PPPangramSansRounded-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Pangram Sans Rounded';
  src: url('/assets/fonts/PPPangramSansRounded-Semibold.woff2') format('woff2'),
  url('/assets/fonts/PPPangramSansRounded-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Pangram Sans Rounded';
  src: url('/assets/fonts/PPPangramSansRounded-Medium.woff2') format('woff2'),
  url('/assets/fonts/PPPangramSansRounded-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Pangram Sans Rounded';
  src: url('/assets/fonts/PPPangramSansRounded-Light.woff2') format('woff2'),
  url('/assets/fonts/PPPangramSansRounded-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/AvenirNextCyr-Heavy.woff2') format('woff2'),
  url('/assets/fonts/AvenirNextCyr-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/AvenirNextCyr-Bold.woff2') format('woff2'),
  url('/assets/fonts/AvenirNextCyr-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
  url('/assets/fonts/AvenirNextCyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
  url('/assets/fonts/AvenirNextCyr-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/AvenirNextCyr-Light.woff2') format('woff2'),
  url('/assets/fonts/AvenirNextCyr-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
