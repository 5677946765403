:root {
  --cplayer-radius: 100px;
  --cplayer-background-color: #fff;
  --cplayer-progress-thickness: 20%;
  --cplayer-progress-base-color: pink;
  --cplayer-progress-active-color: hotpink;
  --cplayer-action-size: 60px;
  --cplayer-action-play-color: grey;
  --cplayer-action-pause-color: silver;
}

@property --percent {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

.cplayer {
  position: relative;
  width: var(--cplayer-radius);
  height: var(--cplayer-radius);
}

.cplayer__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  --height: calc(100% - var(--cplayer-progress-thickness));
  background: radial-gradient(closest-side, var(--cplayer-background-color) var(--height), transparent var(--height) 100%),
  conic-gradient(var(--cplayer-progress-active-color) var(--percent), var(--cplayer-progress-base-color) 0);
  transition: --percent .3s linear;
}

.cplayer__action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  cursor: pointer;
  transition: all .3s ease-in-out;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.cplayer__action svg {
  width: var(--cplayer-action-size);
  height: var(--cplayer-action-size);
}

.cplayer__action--play svg {
  fill: var(--cplayer-action-play-color);
}

.cplayer__action--pause svg {
  fill: var(--cplayer-action-pause-color);
}

.cplayer__action--play,
.cplayer--playing .cplayer__action--pause {
  opacity: 1;
  pointer-events: auto;
  transform: none;
}

.cplayer__action--pause,
.cplayer--playing .cplayer__action--play {
  opacity: 0;
  pointer-events: none;
  transform: rotate(90deg) scale(1.2);
}
