h1 {
  font-family: var(--font-second);
  font-size: var(--font-size-h1);
  font-weight: bold;
  line-height: var(--line-height-h1);
}

h2 {
  font-family: var(--font-second);
  font-size: var(--font-size-h2);
  font-weight: bold;
  line-height: var(--line-height-h2);
  margin-bottom: 1rem;
}

h3 {
  font-family: var(--font-second);
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-h3);
}

h4 {
  font-family: var(--font-second);
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-h4);
}

h5 {
  font-family: var(--font-second);
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-h5);
}

h6 {
  font-family: var(--font-second);
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-h6);
}

p, li {
  font-size: inherit;
  line-height: var(--line-height-medium);
}

p {
  margin-bottom: var(--paragraph-margin);
}

strong {
  font-weight: bold;
}

a {
  color: var(--color-link);
  text-decoration: underline;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      color: var(--color-link-hover);
    }
  }

  &:active {
    color: var(--color-link-active);
  }
}

ol {
  list-style-type: decimal;
}
