@use "normalize.css/normalize";
@use "cplayer/player";
@use 'swiper/scss';
@use 'swiper/scss/effect-coverflow';

@use "./fonts";
@use './vars';
@use './typography';

@use "./material";
@use "./dialog";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 24px;
  -webkit-tap-highlight-color: transparent;

  /* Super extra small devices (phones, 350px and down) */
  @media only screen and (max-width: 350px) {
    font-size: var(--scale-1);
  }

  /* Extra small devices (phones, 350px and up) */
  @media only screen and (min-width: 350px) {
    font-size: var(--scale-2);
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: var(--scale-3);
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: var(--scale-4);
  }

  /* Large devices (laptops/desktops, 1280px and up) */
  @media only screen and (min-width: 1280px) {
    font-size: var(--scale-5);
  }

  /* WXGA+ Display */
  @media screen and (min-width: 1440px) {
    font-size: var(--scale-6);
  }

  /* WSXGA+ Display */
  @media screen and (min-width: 1680px) {
    font-size: var(--scale-7);
  }

  /* Full HD Display */
  @media screen and (min-width: 1920px) {
    font-size: var(--scale-8);
  }

  /* QWXGA Display */
  @media screen and (min-width: 2048px) {
    font-size: var(--scale-9);
  }

  /* QHD Display */
  @media screen and (min-width: 2560px) {
    font-size: var(--scale-10);
  }

  /* WQHD Display */
  @media screen and (min-width: 3440px) {
    font-size: var(--scale-11);
  }

  /* UHD Display */
  @media screen and (min-width: 3840px) {
    font-size: var(--scale-12);
  }

  &.mobile-layout {
    --scale-1: 10px;
    --scale-2: 14px;
    --scale-3: 16px;
    --scale-4: 16px;
    --scale-5: 18px;
    --scale-6: 18px;
    --scale-7: 18px;
    --scale-8: 18px;
    --scale-9: 18px;
    --scale-10: 18px;
    --scale-11: 18px;
    --scale-12: 18px;
  }
}

body {
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-base);
  line-height: 1.4;
  color: var(--color-text);
}

img, canvas {
  display: inline-block;
  vertical-align: top;
}

*:focus {
  outline: none;
}

a,
button {
  &:focus-visible {
    outline: var(--color-focus-outline) solid .15rem;
    outline-offset: 0;
  }
}

strong {
  font-weight: var(--font-weight-medium);
}
