@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
));

@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.dialog-theme($my-theme);
@include mat.snack-bar-theme($my-theme);
@include mat.progress-spinner-theme($my-theme);
@include mat.slider-theme($my-theme);

:root {
  --mdc-dialog-supporting-text-font: var(--font-base);
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #eee;
}

.mdc-slider.mat-mdc-slider {
  --mdc-slider-active-track-height: .4rem;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-inactive-track-height: .4rem;
  --mdc-slider-inactive-track-shape: 9999px;

  --mdc-slider-handle-height: 1.2rem;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 1.2rem;

  --mdc-slider-with-tick-marks-active-container-opacity: 1;
  --mdc-slider-with-tick-marks-container-shape: 0;
  --mdc-slider-with-tick-marks-container-size: 0;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 1;

  --mdc-slider-handle-color: var(--color-slider-handle);
  --mdc-slider-focus-handle-color: var(--color-slider-handle-focus);
  --mdc-slider-hover-handle-color: var(--color-slider-handle-hover);

  --mdc-slider-active-track-color: var(--color-slider-track-active);
  --mdc-slider-inactive-track-color: var(--color-slider-track-inactive);

  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-slider-tick-marks);
  --mdc-slider-with-tick-marks-active-container-color: var(--color-slider-tick-marks);

  .mdc-slider__track--inactive {
    opacity: 1;
  }

  .mdc-slider__tick-marks {
    .mdc-slider__tick-mark--active,
    .mdc-slider__tick-mark--inactive {
      top: -1rem;
      width: .05rem;
      height: .5rem;
      opacity: .4;

      &:first-child,
      &:last-child {
        display: none;
      }
    }
  }
}
